module.exports={
  "refreshRate": 10000,
  "items": [
    {
      "title": "Suppegutt",
      "limits": {
        "red": 60000,
        "orange": 40000,
        "yellow": 20000
      }
    },
    {
      "title": "Suttegutt",
      "limits": {
        "red": 60000,
        "orange": 40000,
        "yellow": 20000
      }
    },
    {
      "title": "Fjærgutt",
      "limits": {
        "red": 60000,
        "orange": 40000,
        "yellow": 20000
      }
    },
    {
      "title": "Grøtgutt",
      "limits": {
        "red": 60000,
        "orange": 40000,
        "yellow": 20000
      }
    }
  ]
}